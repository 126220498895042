import {createApp} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {filters} from "@/filters.js";

import App from "./App.vue";
import ArticlesByTag from "@/views/components/ArticlesByTag.vue";
import ArticleImage from "@/views/components/ArticleImage.vue";
import router from "./core/router.js";
import store from "./store/store.js";

import './font-awesome.js'
import "@/assets/scss/theme.scss";
import 'bootstrap'

const app = createApp(App)
    .use(router)
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('ArticlesByTag', ArticlesByTag)
    .component('ArticleImage', ArticleImage)

app.config.globalProperties.$f = filters

app.mount('#app')
