<template>
    <ul class="main-nav">
        <li v-for="item in menu">
            <!--            <router-link :to="getTagLink(item)" class="ml-1 article-hash">-->
            <!--                <span>{{ firstCharUp(item.name) }}</span>-->
            <!--            </router-link>-->
        </li>
    </ul>
</template>

<script>

import {defineComponent} from "vue";

export default defineComponent({
    name: "Tags",
    props: {
        menu: {
            type: Array,
            required: true
        }
    },
    methods: {
        firstCharUpper(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        getTagLink(item) {
            return {
                name: "tag",
                params: {
                    tag: item.slug
                }
            };
        }
    },
})
</script>

<style lang="scss">
.main-nav {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
        display: inline-block;
        margin-left: 20px;

        a {
            color: #000;
            font-size: 16px;
            line-height: 105%;
            letter-spacing: -.016em;
        }
    }
}
</style>
