import {format, parseISO} from "date-fns";

export const formatDateTime = (date) => {
    return format(parseISO(date), 'dd.MM.yyyy HH:mm:ss');
};


export const dateTimeFilter = (date) => {
    return formatDateTime(date);
};
export const number = (number, minimumFractionDigits = 2) => {
    return Number(number).toLocaleString("sk-SK", {minimumFractionDigits: minimumFractionDigits});
};
export function firstUpper (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const dateFilter = (date) => {
    const months = [
        'Január',
        'Február',
        'Marec',
        'Apríl',
        'Máj',
        'Jún',
        'Júl',
        'August',
        'September',
        'Október',
        'November',
        'December'
    ]
    const iso = parseISO(date);
    const year = format(iso, 'yyyy');
    const month = months[iso.getMonth()];
    return month + ' ' + year;
};

export const filters = {
    firstUpper,
    number
}