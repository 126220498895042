import {library} from "@fortawesome/fontawesome-svg-core";
import {faFacebook, faTwitter, faLinkedin, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {
    faLongArrowAltLeft,
    faSearch,
    faNewspaper,
    faPlusCircle,
    faCheckCircle,
    faTimesCircle,
    faPencil,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faLongArrowAltLeft,
    faSearch,
    faNewspaper,
    faPlusCircle,
    faCheckCircle,
    faTimesCircle,
    faFacebook,
    faTwitter,
    faLinkedin,
    faWhatsapp,
    faPencil,
);
