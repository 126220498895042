<script>
    import {defineComponent} from "vue";

    export default defineComponent({
        name: "ArticleImage",
        props: {
            detail: {
                type: Object,
                required: true
            },
            fullMode: {
                type: Boolean,
                default: false
            },
            height: {
                type: Number,
                required: false,
            },
            width: {
                type: Number,
                required: false,
                default: 920
            }
        },
        computed: {
            maxWidth() {
                if (this.$refs.articleGallery !== undefined) {
                    return this.$refs.articleGallery.clientWidth;
                }
                return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            }
        },
        mounted() {
            this.image = this.$attrs["data-image"];
            this.articleImage = this.getImage(this.image);
        },
        methods: {
            getImageSizing(imageUrl) {
                if (!imageUrl) {
                    return ''
                }
                return imageUrl.slice(imageUrl.length - 3) === "gif"
                    ? ""
                    : "?width=" + this.width + (this.height ? '&height=' + this.height : '');
            },
            getImage(id) {
                return this.detail.gallery.find(item => {
                    return item.id === parseInt(id);
                });
            }
        },
        data() {
            return {
                image: null,
                articleImage: null,
            };
        }
    })
</script>

<template>
    <div ref="articleGallery" class="single-article-wrapper article-content-wrapper">
        <img :src="articleImage.link + getImageSizing(articleImage.link)"
             :alt="detail.article.title"
             v-if="articleImage"
             class="article-single-image"
             loading="lazy"
             :width="maxWidth"
             :height="height ? 450 : height"
        />
        <img :src="image + getImageSizing(image)"
             :alt="detail.article.title"
             v-if="!articleImage"
             class="article-single-image"
             loading="lazy"
             :width="maxWidth"
             :height="height ? 450 : height"
        />
    </div>
</template>

<style lang="scss">
    .single-article-wrapper {
        text-align: center;

        .article-single-image {
            width: auto ! important;
            max-height: 700px;
            max-width: 100%;
            border-radius: 12px;
        }
    }
</style>
