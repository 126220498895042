import api from "../api.js";

export const defaultProduct = {
    items: [],
    paginator: {
        items: 0,
        page: 1,
        pages: 1,
        perPage: 12,
    }
};

export default {
    namespaced: true,
    state: {
        product: {...defaultProduct},
    },
    getters: {},
    actions: {
        product(store, data = {}) {
            api.post("product/list/" + data.page + "/16", {})
                .then((response) => {
                    store.commit("setProduct", response.data);
                })
                .catch(error => {
                    store.commit("resetProduct");
                });
        },

    },
    mutations: {
        setProduct(state, data = []) {
            state.product.items = data.items
            state.product.paginator = data.paginator
        },
        resetProduct(state) {
            state.product = {...defaultProduct};
        },
    }
};
