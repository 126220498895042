<template>
    <section class="main-tags-wrapper">
        <h2 class="h2">Obľúbené témy</h2>
        <div class="main-tags">
            <router-link :to="getTagLink(tag)" v-for="tag in tags">
                <span>{{ $f.firstUpper(tag.name)}}</span>
            </router-link>
        </div>
    </section>

</template>

<script>
    import {defineComponent} from "vue";

    export default defineComponent({
        name: "MainTags",
        mounted() {
            this.$store.dispatch('tags/fav');
        },
        computed: {
            tags() {
                return this.$store.state.tags.tags;
            }
        },
        methods: {
            getTagLink(item) {
                return {
                    name: "tag",
                    params: {
                        tag: item.slug
                    }
                };
            }
        }
    })
</script>

<style lang="scss">
    .main-tags-wrapper {
        margin-top: 60px;
        margin-bottom: 60px;

        .main-tags {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;

            a {
                color: #000;
                margin-right: 15px;
                white-space: nowrap;
                line-height: 150%;

                &:nth-child(-n+16) {
                    font-size: 20px;
                }

                &:nth-child(-n+8) {
                    font-size: 25px;
                }
            }
        }
    }
</style>