import Vuex from "vuex";
import articles from "@/store/modules/articles";
import tags from "@/store/modules/tags";
import meta from "@/store/modules/meta";
import category from "@/store/modules/category";
import product from "@/store/modules/product";
import smarthome from "@/store/modules/smarthome";
import faq from "@/store/modules/faq";
import scene from "@/store/modules/scene";
import alert from "@/store/modules/alert";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    articles,
    tags,
    meta,
    category,
    product,
    smarthome,
    faq,
    scene,
    alert,
  }
});
