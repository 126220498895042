import api from "../api.js";

export default {
    namespaced: true,
    state: {
        items: [],
        loading: true,
    },
    getters: {},
    actions: {
        homepage(store) {
            store.commit("setLoading", true);
            api.get('faq/homepage')
                .then((response) => {
                    store.commit("setItems", response.data);
                    store.commit("setLoading", false);
                })
                .catch(error => {
                    store.commit("setItems", null);
                    store.commit("setLoading", false);
                });
        },
        all(store) {
            store.commit("setLoading", true);
            api.get('faq/all')
                .then((response) => {
                    store.commit("setItems", response.data);
                    store.commit("setLoading", false);
                })
                .catch(error => {
                    store.commit("setItems", null);
                    store.commit("setLoading", false);
                });
        }
    },
    mutations: {
        setItems(state, data = []) {
            state.items = data;
        },
        setLoading(state, newState) {
            state.loading = newState;
        },
    }
};
