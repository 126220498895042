<template>
    <!-- ========== FOOTER ========== -->
    <footer class="bg-dark">
        <div class="container">
            <div class="border-bottom border-white-10">
                <div class="row py-10">
                    <div class="col-6 col-sm-4 col-lg mb-7 mb-lg-0">
                        <span class="text-cap text-white">Smart home</span>

                        <!-- List -->
                        <ul class="list-unstyled list-py-1 mb-0">
                            <li>
                                <router-link :to="{name: 'article', params: {slug: 'ako-zacat-so-smart-home'}}"
                                             class="link link-light link-light-75"
                                >
                                    Ako začať so smart home?
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'article', params: {slug: 'smart-home-inspiracia'}}"
                                             class="link link-light link-light-75"
                                >
                                    Čo dokáže smart home?
                                </router-link>
                            </li>
<!--                            <li>-->
<!--                                <router-link :to="{name: 'planner'}" class="link link-light link-light-75">-->
<!--                                    Ako na smart home?-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li>
                                <router-link :to="{name: 'products'}" class="link link-light link-light-75">
                                    Aké smart home zariadenia si vybrat?
                                </router-link>
                            </li>
                        </ul>
                        <!-- End List -->
                    </div>
                    <!-- End Col -->


                    <div class="col-6 col-sm-4 col-lg mb-7 mb-sm-0">
                        <span class="text-cap text-white">Technológie</span>

                        <!-- List -->
                        <ul class="list-unstyled list-py-1 mb-0">
                            <li>
                                <router-link :to="{name: 'tag', params: {tag: 'homekit'}}" class="link link-light link-light-75">
                                    Homekit
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'tag', params: {tag: 'matter'}}" class="link link-light link-light-75">
                                    Matter
                                </router-link>
                            </li>
<!--                            <li>-->
<!--                                <router-link :to="{name: 'tag', params: {tag: 'google-home'}}" class="link link-light link-light-75">-->
<!--                                    Google home-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <router-link :to="{name: 'tag', params: {tag: 'xiaomi-home'}}" class="link link-light link-light-75">-->
<!--                                    Xiaomi home-->
<!--                                </router-link>-->
<!--                            </li>-->
                        </ul>
                        <!-- End List -->
                    </div>
                    <!-- End Col -->


                    <div class="col-6 col-sm-4 col-lg mb-7 mb-lg-0">
                        <span class="text-cap text-white">Smartan</span>

                        <!-- List -->
                        <ul class="list-unstyled list-py-1 mb-0">

                            <li>
                                <router-link :to="{name: 'aboutUs'}" class="link link-light link-light-75">
                                    O nás
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'faq'}" class="link link-light link-light-75">
                                    FAQ
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'aboutUs'}" class="link link-light link-light-75">
                                    Kontakt
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'aboutUs'}" class="link link-light link-light-75">
                                    Pridaj sa k nám
                                </router-link>
                            </li>
                        </ul>
                        <!-- End List -->
                    </div>
                    <!-- End Col -->

                    <div class="col-6 col-sm-4 col-lg">
                        <span class="text-cap text-white">Sledujte nás</span>

                        <!-- List -->
                        <ul class="list-unstyled list-py-2 mb-0">
<!--                            <li>-->
<!--                                <a class="link link-light link-light-75" href="#">-->
<!--                                    <div class="d-flex">-->
<!--                                        <div class="flex-shrink-0">-->
<!--                                            <i class="bi-envelope-open-fill"></i>-->
<!--                                        </div>-->

<!--                                        <div class="flex-grow-1 ms-2">-->
<!--                                            <span>Newsletter</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a class="link link-light link-light-75" href="#">-->
<!--                                    <div class="d-flex">-->
<!--                                        <div class="flex-shrink-0">-->
<!--                                            <i class="bi-youtube"></i>-->
<!--                                        </div>-->

<!--                                        <div class="flex-grow-1 ms-2">-->
<!--                                            <span>Youtube</span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </li>-->
                            <li>
                                <a class="link link-light link-light-75" href="#">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <i class="bi-facebook"></i>
                                        </div>

                                        <div class="flex-grow-1 ms-2">
                                            <span>Facebook</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a class="link link-light link-light-75" href="#">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <i class="bi-instagram"></i>
                                        </div>

                                        <div class="flex-grow-1 ms-2">
                                            <span>Instagram</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <!-- End List -->
                    </div>
                    <!-- End Col -->


                </div>
                <!-- End Row -->
            </div>

            <!-- End Row -->
        </div>
    </footer>
    <!-- ========== END FOOTER ========== -->
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "FooterSection"
})
</script>

<style scoped>

</style>