import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/homepage/Home.vue")
    }, {
        path: "/coming-soon",
        name: "comingSoon",
        component: () => import("../views/coming-soon/ComingSoon.vue")
    }, {
        path: "/o-nas",
        name: "aboutUs",
        component: () => import("../views/aboutus/AboutUs.vue")
    }, {
        path: "/prihlasit-sa",
        name: "login",
        component: () => import("../views/login/Login.vue"),
        meta: {
            newsletter: false,
        }
    }, {
        path: "/registracia",
        name: "register",
        component: () => import("../views/register/Register.vue"),
        meta: {
            newsletter: false,
        }
    }, {
        path: "/faq",
        name: "faq",
        component: () => import("../views/faq/Faq.vue")
    }, {
        path: "/tag-:tag",
        name: "tag",
        component: () => import("../views/homepage/Home.vue")
    }, {
        path: "/produkty/:slug?",
        name: "products",
        component: () => import("../views/shop/Shop.vue")
    },

    {
        path: "/:slug",
        name: "article",
        component: () => import("../views/blog/Article.vue")
    },
    {
        path: "/:catchAll(.*)",
        name: "notFound",
        component: () => import("../views/NotFound.vue"),
        meta: {
            newsletter: false,
        }
    }
];

const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        return {
            el: to.hash,
            behavior: 'smooth',
        }
    }
    return { top: 0 }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior,
});

export default router;
