import api from "../api.js";

export default {
    namespaced: true,
    state: {
        codeBook: [],
    },
    getters: {},
    actions: {
        codeBook(store) {
            api.get("category/code-book")
                .then((response) => {
                    store.commit("setCodeBook", response.data);
                })
                .catch(error => {
                    store.commit("resetCodeBook");
                });
        },

    },
    mutations: {
        setCodeBook(state, data = []) {
            state.codeBook = data
        },
        resetCodeBook(state) {
            state.codeBook = [];
        },
    }
};
