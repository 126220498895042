import api from "../api.js";

export const defaultScenes = {
    items: [],
    paginator: {
        items: 0,
        page: 1,
        pages: 1,
        perPage: 12,
    }
};

export default {
    namespaced: true,
    state: {
        scenes: {...defaultScenes},
        loading: true,
    },
    getters: {},
    actions: {
        list(store, data) {
            store.commit("setLoading", true);
            api.post('scene/list/1/10', {})
                .then((response) => {
                    store.commit("setScenes", response.data);
                    store.commit("setLoading", false);
                })
                .catch(error => {
                    store.commit("setScenes", null);
                    store.commit("setLoading", false);
                });
        },
    },
    mutations: {
        setScenes(state, data = []) {
            state.scenes = data;
        },
        setLoading(state, newState) {
            state.loading = newState;
        },
    }
};
