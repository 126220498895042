<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "ArticlesByTag",
    props: {
        limit: {
            required: true,
        },
        tag: {
            required: true,
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.$store.dispatch('articles/getTags', {tag: this.tag, limit: this.limit}).then((response) => {
                this.items = response.data.items
            })
        }
    },
    data() {
        return {
            items: []
        }
    }
})
</script>


<template>
    <ul class="articles-by-tag">
        <li class="" v-for="item in items">
            <router-link :to="{name: 'article', params: {slug: item.slug}}">
                <img :src="item.image + '?width=40&height=40'"
                     :alt="item.description"
                     loading="lazy"
                />
                <span class="tag-title">{{ item.title }}</span>
            </router-link>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
.articles-by-tag {
    list-style: none;
    padding: 0;
    margin: 0 0 15px;

    > li {
        > a {
            background: #fff;
            padding-bottom: 8px;

            display: flex;
            align-items: center;
            border-radius: 0.5rem;

            padding-left: 0;
            transition: 0.30s all ease;

            &.router-link-active {
                font-weight: bold;
            }

            &:hover {
                padding-left: 5px;
            }

            img {
                border-radius: 5px;
            }

            .tag-title {
                margin-left: 10px;
                color: #2d374b;
            }
        }
    }
}
</style>