<template>
    <div class="pt-10">
        <main-header v-if="$route.name !== 'comingSoon'"/>
        <router-view/>
        <template v-if="$route.name !== 'comingSoon'">
            <subscribe v-if="newsletter === true"/>
            <footer-section/>
        </template>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

import Tags from "@/components/Tags.vue";
import MainTags from "@/components/MainTags.vue";
import Subscribe from "@/components/Subscribe.vue";
import FooterSection from "@/components/FooterSection.vue";
import MainHeader from "@/components/MainHeader/MainHeader.vue";

export default defineComponent({
    components: {
        MainHeader,
        FooterSection,
        Subscribe,
        MainTags,
        Tags
    },
    mounted() {
        // this.$store.dispatch('account/get')
        this.lockWebsite()
    },
    computed: {
        origin() {
            return window.location.hostname;
        },
        newsletter() {
            return this.$route.meta.newsletter === undefined ? true : this.$route.meta.newsletter
        }
    },
    methods: {
        lockWebsite() {
            if (process.env.NODE_ENV !== "development"
                && localStorage.getItem('lock') !== 'mozem'
                && this.$route.name !== 'comingSoon'
            ) {
                this.$router.push({name: 'comingSoon'});
            }
        },
    },
    data() {
        return {
            projectTitle: process.env.VUE_APP_TITLE,
            facebookLink: process.env.VUE_APP_FB,
            year: new Date().getFullYear(),
        };
    }
})
</script>

<style lang="scss">
.mobile-menu {
    background: #f7f7f7;
    margin-bottom: 15px;
    border-bottom: 2px solid #ebebeb;
    padding: 10px 10px 10px 0;
    white-space: nowrap;
    overflow-y: hidden;
}

.main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
    backdrop-filter: blur(8px);

    @include media-breakpoint-down(md) {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;
    }

    > .container {
        position: relative;
        display: flex;
        align-items: center;

        .main-nav {
            margin-left: 20px;
        }

        .search {
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #686868;

            @include media-breakpoint-down(md) {
                right: 5px;
                justify-content: center;
            }
        }
    }
}


.logo {
    font-size: 26px;
    font-weight: bold;
    color: #000 ! important;
    text-decoration: none;

    @include media-breakpoint-down(md) {
        font-size: 24px;
    }

    span {
        color: #686868;
    }
}

.main-footer {
    padding-top: 30px;
    padding-bottom: 40px;
    border-top: 2px solid #ebebeb;
    /*color: #757575;*/

    .footer-text {
        font-size: 14px;
        color: #757575;
        margin-top: 3px;
    }

    ul {
        margin-top: 10px;
        padding-left: 18px;
        margin-bottom: 0;

        a {
            color: #757575;
            font-size: 15px;
        }
    }


}
</style>
