<template>
    <!-- ========== HEADER ========== -->
    <header class="navbar navbar-expand-lg navbar-sticky-top navbar-light"
            :class="{'navbar-scrolled': scrolled}"
    >
        <div class="container">
            <nav class="navbar-nav-wrap">
                <!-- Default Logo -->
                <router-link :to="{name: 'home'}" class="navbar-brand" aria-label="BeSmartan">
                    <img class="navbar-brand-logo"
                         src="@/assets/svg/smartan.svg"
                         alt="Smartan - smart home"
                         width="144"
                         height="32"
                    >
                </router-link>
                <!-- End Default Logo -->

                <!-- Toggler -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span class="navbar-toggler-default">
                      <i class="bi-list"></i>
                        Menu
                    </span>
                    <span class="navbar-toggler-toggled">
                        <i class="bi-x"></i>
                        Menu
                    </span>
                </button>
                <!-- End Toggler -->

                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav nav-pills">
                        <li class="nav-item">
                            <router-link :to="{name: 'home'}" class="nav-link">
                                Smart home
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'article', params: {slug: 'ako-zacat-so-smart-home'}}"
                                         class="nav-link">
                                Začíname
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'article', params: {slug: 'smart-home-inspiracia'}}"
                                         class="nav-link">
                                Inšpirácia
                            </router-link>
                        </li>

                        <!--                        <li class="nav-item">-->
                        <!--                            <router-link :to="{name: 'inspirationList', params: {page: 1}}" class="nav-link">-->
                        <!--                                Inšpirácia-->
                        <!--                            </router-link>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav-item">-->
                        <!--                            <router-link :to="{name: 'planner'}" class="nav-link">-->
                        <!--                                Planner-->
                        <!--                            </router-link>-->
                        <!--                        </li>-->
                        <li class="nav-item">
                            <router-link :to="{name: 'products'}" class="nav-link">
                                Produkty
                            </router-link>
                        </li>

                        <!--                        &lt;!&ndash; Log in &ndash;&gt;-->
                        <!--                        <li class="nav-item ms-lg-auto">-->
                        <!--                            <router-link :to="{name: 'login'}" class="btn btn-ghost-dark me-2 me-lg-0">-->
                        <!--                                Prihlásiť sa-->
                        <!--                            </router-link>-->
                        <!--                            <router-link :to="{name: 'register'}" class="btn btn-dark d-lg-none">-->
                        <!--                                Registrácia-->
                        <!--                            </router-link>-->
                        <!--                        </li>-->
                        <!--                        &lt;!&ndash; End Log in &ndash;&gt;-->
                        <!--                        &lt;!&ndash; Sign up &ndash;&gt;-->
                        <!--                        <li class="nav-item">-->
                        <!--                            <router-link :to="{name: 'register'}"  class="btn btn-dark d-none d-lg-inline-block">-->
                        <!--                                Registrácia-->
                        <!--                            </router-link>-->
                        <!--                        </li>-->
                    </ul>
                </div>
                <!-- End Collapse -->
            </nav>
        </div>
    </header>
    <!-- ========== END HEADER ========== -->
</template>

<script>

import {defineComponent} from "vue";

export default defineComponent({
    name: "MainHeader",
    created() {
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrolled = window.scrollY > 100;
        }
    },
    data() {
        return {
            scrolled: false,
        }
    }
})
</script>

<style lang="scss" scoped>
.navbar {
    &.navbar-light {
        background: rgba(255, 255, 255, 0.8) ! important;
        backdrop-filter: blur(10px);
    }

    .navbar-brand-logo {
        height: auto;
    }

    //&.navbar-scrolled,
    //&.navbar-sticky-top {
    //    @include media-breakpoint-down(md) {
    //        top: auto;
    //        bottom: 0;
    //        box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, .09) ! important;
    //    }
    //}
}
</style>