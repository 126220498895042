import api from "../api.js";

export default {
    namespaced: true,
    state: {
        tag: null,
        loading: true,
        tags: [],
    },
    getters: {},
    actions: {
        tag(store, slug) {
            return new Promise((resolve, reject) => {
                store.commit("setLoading", true);
                api
                    .get("tag/by-slug/" + slug)
                    .then((response) => {
                        store.commit("setTag", response.data);
                        store.commit("setLoading", false);
                        resolve(undefined);
                    })
                    .catch(error => {
                        store.commit("setTag", null);
                        store.commit("setLoading", false);
                    });
            });
        },
        fav(store) {
            api.get('tag/favourite/100').then((response) => {
                store.commit('setTags', response.data);
            })
        }
    },
    mutations: {
        setLoading(state, newState) {
            state.loading = newState;
        },
        setTag(state, newState) {
            state.tag = newState;
        },
        setTags(state, newState) {
            state.tags = newState;
        }
    }
};
