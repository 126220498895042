<template>
    <!-- Subscribe -->
    <div class="overflow-hidden">
        <div class="container content-space-b-2 content-space-b-lg-3">
            <div class="position-relative bg-soft-primary rounded-3 p-7">
                <div class="row justify-content-lg-between align-items-lg-center">
                    <div class="col-lg-6 mb-3 mb-lg-0">
                        <h2>Get industry insights that you won't delete, straight in your inbox</h2>
                    </div>
                    <!-- End Col -->

                    <div class="col-lg-5">
                        <form>
                            <!-- Input Card -->
                            <div class="input-card input-card-sm mb-3">
                                <div class="input-card-form">
                                    <label for="subscribeFormEg1" class="form-label visually-hidden">Enter email</label>
                                    <input type="email" class="form-control form-control-lg" id="subscribeFormEg1" placeholder="Enter email" aria-label="Enter email">
                                </div>
                                <button type="button" class="btn btn-primary btn-lg" id="heroNameAddOnEg1">Subscribe</button>
                            </div>
                            <!-- End Input Card -->
                        </form>
                    </div>
                    <!-- End Col -->
                </div>
                <!-- End Row -->

                <!-- SVG Shape -->
                <figure class="position-absolute top-0 end-0 zi-n1 d-none d-md-block mt-10 me-n10" style="width: 4rem;">
                    <img class="img-fluid"
                         src="../assets/svg/components/pointer-up.svg"
                         alt="Smart home novinky"
                         width="64"
                         height="71"
                         loading="lazy"
                    >
                </figure>
                <!-- End SVG Shape -->

                <!-- SVG Shape -->
                <figure class="position-absolute bottom-0 start-0 zi-n1 ms-n10 mb-n10" style="width: 15rem;">
                    <img class="img-fluid"
                         src="../assets/svg/components/curved-shape.svg"
                         alt="Smart home newsletter"
                         width="240"
                         height="130"
                         loading="lazy"
                    >
                </figure>
                <!-- End SVG Shape -->
            </div>
        </div>
    </div>
    <!-- End Subscribe -->
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "Subscribe"
})
</script>

<style scoped>

</style>