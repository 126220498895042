import api from "../api.js";
import {formatISO} from "date-fns";

const defaultListing = {
    items: [],
    paginator: {
        page: 0,
        pages: 0
    }
};

export default {
    namespaced: true,
    state: {
        listing: {...defaultListing},
        loading: true,
        detail: null
    },
    getters: {},
    actions: {
        getTags(store, params) {
            const limit = params.limit || 8;
            const data = [{
                key: "articleTag->tag->slug",
                value: params.tag
            },{
                key: "publishedOn",
                operator: "!=",
                value: null
            }];
            return api.post("article/list/1/" + limit, {conditions: data})
        },
        list(store, params) {
            const page = params.page || 1;
            const perPage = params.perPage || 8;
            params.conditions = [
                {
                    key: "publishedOn",
                    operator: "<=",
                    value: formatISO(new Date())
                }
            ];

            if (params.search.length >= 2) {
                params.conditions.push({
                    key: ["title", "perex", "articleTag->tag->name"],
                    value: params.search,
                    type: "fulltext",
                    conjunction: "AND",
                    minLength: 2
                });
            }

            if (params.tag !== null) {
                params.conditions.push({
                    key: "articleTag->tag->slug",
                    value: params.tag
                });
            }

            params.sort = {
                publishedOn: "DESC",
                id: "DESC"
            };
            store.commit("setLoading", true);
            api
                .post("article/list/" + page + "/" + perPage, params)
                .then((response) => {
                    store.commit("setListing", response.data);
                    store.commit("setLoading", false);
                })
                .catch(error => {
                    store.commit("resetListing");
                    store.commit("setLoading", false);
                });
        },
        detail(store, slug) {
            return new Promise((resolve, reject) => {
                store.commit("setLoading", true);

                if (slug === null) {
                    reject();
                }

                api
                    .get("article/slug/" + slug)
                    .then((response) => {
                        store.commit("setArticle", response.data);
                        store.commit("setLoading", false);
                        resolve(undefined);
                    })
                    .catch(error => {
                        store.commit("setArticle", null);
                        store.commit("setLoading", false);
                    });
            });
        }
    },
    mutations: {
        setListing(state, data = []) {
            state.listing.items = data.items;
            state.listing.paginator = data.paginator;
        },
        resetListing(state) {
            state.listing = {...defaultListing};
        },
        setLoading(state, newState) {
            state.loading = newState;
        },
        setArticle(state, newState) {
            state.detail = newState;
        }
    }
};
