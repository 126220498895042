const defaultRooms = [
    {
        name: 'Obývačka'
    }, {
        name: 'Spálňa'
    }, {
        name: 'Kuchyňa'
    }, {
        name: 'Vstupná hala'
    }, {
        name: 'WC'
    }, {
        name: 'Kúpeľňa'
    }
];

export default {
    namespaced: true,
    state: {
        rooms: [...defaultRooms],
    },
    getters: {},
    actions: {
        saveRooms(store) {
            store.commit('cleanEmptyRooms')

            console.log(store.state.rooms)
        }
    },
    mutations: {
        addRoom(state) {
            state.rooms.push({name: ''})
        },
        removeRoom(state, index) {
            state.rooms.splice(index, 1);
        },
        cleanEmptyRooms(state) {
            state.rooms = state.rooms.filter((room) => {
                return room.name.length >= 2;
            })
        }
    }
};
