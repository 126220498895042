import axios, {AxiosRequestConfig} from "axios/index";
// import {i18n} from "../main";

export const csrfKey = process.env.VUE_APP_API_CSRF || 'csrf';

export const apiLink = () => {
    return process.env.VUE_APP_API_LINK;
};

export const prepareQueryParameters = (parameters) => {
    let out = "";
    Object.keys(parameters).forEach(key => {
        if (out !== "") {
            out += "&";
        }
        out += key + "=" + encodeURIComponent(parameters[key]);
    });
    return out;
};

export default {
    getAxiosConfig(queryParams = {}) {
        // @ts-ignore
        axios.defaults.headers.common['Authorization'] = localStorage.getItem(csrfKey);
        axios.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response;
                if (status === 401) {
                    console.log("validate login");
                }
                return Promise.reject(error);
            }
        );

        return {
            withCredentials: true,
            params: queryParams,
        };
    },

    get(link) {
        return axios.get(apiLink() + link, this.getAxiosConfig());
    },

    getRequest(store, url) {
        return new Promise((resolve, reject) => {
            this.get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response);
                });
        });
    },

    post(link, data = {}) {
        return axios.post(apiLink() + link, data, this.getAxiosConfig());
    },

    put(link, data = {}) {
        return axios.put(apiLink() + link, data, this.getAxiosConfig());
    },

    putWithNotification(store, link, data = {}, name = "Item") {
        return new Promise((resolve, reject) => {
            this.put(link, data)
                .then(response => {
                    store.dispatch("alert/success", name, {
                        root: true
                    });
                    resolve(response.data);
                })
                .catch(error => {
                    store.dispatch("alert/warning", error.response.data.status, {
                        root: true
                    });
                    reject(error.response);
                });
        });
    },

    postWithNotification(
        store,
        link,
        data = {},
        name = "Item"
    ) {
        return new Promise((resolve, reject) => {
            this.post(link, data)
                .then(response => {
                    if (name !== false) {
                        store.dispatch("alert/success", name, {
                            root: true
                        });
                    }
                    resolve(response.data);
                })
                .catch(error => {
                    if (name !== false) {
                        store.dispatch("alert/warning", error.response.data.status, {
                            root: true
                        });
                    }
                    reject(error.response);
                });
        });
    },

    delete(link) {
        return axios.delete(apiLink() + link, this.getAxiosConfig());
    }
};
