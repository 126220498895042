const stripHtml = (html) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const appendToHead = (tag) => {
  document.getElementsByTagName("head")[0].appendChild(tag);
};

const createMetaTag = (name, content, type = "name") => {
  // Delete old
  const oldMetaTag = document.querySelector("[" + type + "='" + name + "']");
  if (oldMetaTag !== null) {
    oldMetaTag.remove();
  }
  // Create new one
  const meta = document.createElement("meta");
  meta.setAttribute(type, name);
  meta.setAttribute("content", content);
  appendToHead(meta);
};

const defaultTitle = process.env.VUE_APP_DEFAULT_TITLE + " - " + process.env.VUE_APP_TITLE;
const defaultDescription = process.env.VUE_APP_DESCRIPTION;
const defaultImage = process.env.VUE_APP_IMAGE;

export default {
  namespaced: true,
  state: {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage
  },
  getters: {},
  actions: {},
  mutations: {
    setTitle(state, text) {
      state.title =
        text === null
          ? defaultTitle
          : text + " - " + stripHtml(process.env.VUE_APP_TITLE);
      document.title = state.title;
      createMetaTag("og:title", state.title, "property");
    },
    setDescription(state, text) {
      state.description = text === null ? defaultDescription : text;
      createMetaTag("og:description", state.description, "property");
      createMetaTag("description", state.description);
    },
    setImage(state, link) {
      state.image = link === null ? defaultImage : link;
      createMetaTag("og:image", state.image, "property");
    }
  }
};
